export const LIST_PROGRAMS = [
  {
    id: 'program-id-clinicare',
    name: 'Clinicare',
    image: 'https://placehold.co/1280',
    description: 'Clinicare Diet Program adalah solusi untuk kondisi khusus/penyakit yang membutuhkan perbaikan pada makan secara holistik',
  }, {
    id: 'program-id-body-for-baby',
    name: 'Body for Baby',
    image: 'https://placehold.co/1280',
    description: 'Program Body for Baby memastikan calon ibu terpenuhi kebutuhan gizinya, lewat program yang mudah diterapkan.',
  }, {
    id: 'program-id-body-goals',
    name: 'Body Goals',
    image: 'https://placehold.co/1280',
    description: 'Program diet Personalized ini dirancang untuk kamu yang mau mencapai berat badan ideal. Tidak tersiksa, aman, hasil permanen, terbukti.',
  }, {
    id: 'program-id-for-enterprise',
    name: 'For Enterprise',
    image: 'https://placehold.co/1280',
    description: 'Mulai hidup sehat di kantormu, bersama Dietela.',
  },
]