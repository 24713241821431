var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', {
    attrs: {
      "background-color": ['#F2F2F2', '#FFF'],
      "width": "100%"
    }
  }, [_c('c-box', {
    attrs: {
      "position": "relative",
      "mx": "auto",
      "mt": _vm.isAuthenticated ? '0' : '16px',
      "mb": ['0', '16px'],
      "background-color": ['#FFF', '#FFF'],
      "border": "1px solid #f2f2f2",
      "box-shadow": ['none', '2px 2px 10px rgba(0, 0, 0, 0.15)'],
      "border-radius": ['0px', '16px'],
      "min-height": ['calc(100vh - 62px)', '74vh'],
      "width": "100%",
      "max-width": "1270px"
    }
  }, [_vm.isAuthenticated ? _c('Portal', {
    attrs: {
      "to": "breadcrumb"
    }
  }, [_c('c-box', {
    attrs: {
      "width": "100%",
      "max-width": "1270px",
      "mx": "auto"
    }
  }, [_c('BreadcrumbPath', {
    attrs: {
      "px": "90px",
      "py": "20px",
      "paths": [{
        label: 'Dashboard',
        href: '/'
      }, {
        label: 'Program',
        isCurrent: true
      }]
    }
  })], 1)], 1) : _vm._e(), _c('c-box', {
    attrs: {
      "padding": ['16px 16px 0 16px']
    }
  }, [_c('c-box', {
    attrs: {
      "background-color": "#FAF59A",
      "width": "100%",
      "height": ['114px', '114px'],
      "border-radius": "12px",
      "display": "flex",
      "justify-content": "center",
      "align-items": "center",
      "position": "relative",
      "overflow": "hidden"
    }
  }, [_c('c-box', [_c('c-box', {
    attrs: {
      "position": "absolute",
      "top": "0",
      "left": "0"
    }
  }, [_c('svg', {
    attrs: {
      "width": "79",
      "height": "64",
      "viewBox": "0 0 79 64",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M0 -9H59L50.5 26.5L79 64H0V-9Z",
      "fill": "#F1E456"
    }
  })])]), _c('c-box', {
    attrs: {
      "position": "absolute",
      "bottom": "0",
      "left": "0"
    }
  }, [_c('svg', {
    attrs: {
      "width": "79",
      "height": "37",
      "viewBox": "0 0 79 37",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M0 0L55.5 22L79 73H0V0Z",
      "fill": "#F1E456"
    }
  })])]), _c('c-box', {
    attrs: {
      "position": "absolute",
      "top": "0",
      "left": "50%",
      "transform": "translateX(-50%)"
    }
  }, [_c('svg', {
    attrs: {
      "width": "93",
      "height": "64",
      "viewBox": "0 0 93 64",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M5 -9H84L93 26L84 64H5L0 26L5 -9Z",
      "fill": "#F1E456"
    }
  })])]), _c('c-box', {
    attrs: {
      "position": "absolute",
      "bottom": "0",
      "left": "50%",
      "transform": "translateX(-50%)"
    }
  }, [_c('svg', {
    attrs: {
      "width": "79",
      "height": "41",
      "viewBox": "0 0 79 41",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M0 11L79 0.5V84H0V11Z",
      "fill": "#F1E456"
    }
  })])]), _c('c-box', {
    attrs: {
      "position": "absolute",
      "top": "50%",
      "right": "0",
      "transform": "translateY(-50%)"
    }
  }, [_c('svg', {
    attrs: {
      "width": "56",
      "height": "77",
      "viewBox": "0 0 56 77",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M12.5 0.5L49.5 12.5L95 4V77H16L0.5 42L12.5 0.5Z",
      "fill": "#F1E456"
    }
  })])])], 1), _c('c-box', {
    attrs: {
      "position": "absolute",
      "height": "100%",
      "width": "100%",
      "display": "flex",
      "justify-content": "center",
      "align-items": "center",
      "gap": "16px"
    }
  }, [_c('BaseText', {
    attrs: {
      "size-mobile": "16px",
      "size-desktop": "18px",
      "color": "#008C81"
    }
  }, [_vm._v(" Pilih Program "), _c('br'), _vm._v(" Terbaik Untukmu ")]), _c('c-image', {
    attrs: {
      "height": "93.66px",
      "width": "120px",
      "src": require('@/assets/images/illustration-10-raw.png')
    }
  })], 1)], 1)], 1), _c('c-box', {
    attrs: {
      "padding": ['0 16px 16px 16px'],
      "margin-top": "16px",
      "display": "grid",
      "grid-template-columns": "repeat(2, 1fr)",
      "gap": "16px"
    }
  }, _vm._l(_vm.listProgram, function (program) {
    return _c('c-box', {
      directives: [{
        name: "chakra",
        rawName: "v-chakra",
        value: {
          _hover: {
            boxShadow: '2px 2px 10px rgba(0, 0, 0, 0.25)',
            backgroundColor: '#f1f1f1'
          },
          _active: {
            boxShadow: '2px 2px 10px rgba(0, 0, 0, 0.25)',
            backgroundColor: '#eaeaea'
          },
          'img': {
            transition: 'all 0.2s ease-in-out'
          },
          'img:hover': {
            transform: 'scale(1.1)'
          }
        },
        expression: "{\n          _hover: {\n            boxShadow: '2px 2px 10px rgba(0, 0, 0, 0.25)',\n            backgroundColor: '#f1f1f1',\n          },\n          _active: {\n            boxShadow: '2px 2px 10px rgba(0, 0, 0, 0.25)',\n            backgroundColor: '#eaeaea',\n          },\n          'img': {\n            transition: 'all 0.2s ease-in-out',\n          },\n          'img:hover': {\n            transform: 'scale(1.1)',\n          },\n        }"
      }],
      key: program.id,
      attrs: {
        "as": "button",
        "text-align": "start",
        "box-shadow": ['2px 2px 10px rgba(0, 0, 0, 0.15)'],
        "border-radius": "8px",
        "width": "100%",
        "display": "flex",
        "flex-direction": "column",
        "justify-content": "center",
        "align-items": "center",
        "overflow": "hidden"
      },
      on: {
        "click": function click($event) {
          return _vm.handleClickProgram(program);
        }
      }
    }, [_c('c-box', {
      attrs: {
        "min-height": "102px",
        "height": "100%",
        "max-height": ['132px', '252px'],
        "overflow": "hidden",
        "width": "100%"
      }
    }, [_c('c-image', {
      attrs: {
        "src": program.photoUrl,
        "object-fit": "cover",
        "width": "100%",
        "height": "100%",
        "object-position": "50% 20%"
      }
    })], 1), _c('c-box', {
      attrs: {
        "width": "100%",
        "padding": "10px",
        "margin-bottom": "auto"
      }
    }, [_c('BaseText', {
      attrs: {
        "size-mobile": "14px",
        "size-desktop": "16px-2"
      }
    }, [_vm._v(" " + _vm._s(program.name) + " ")]), _c('BaseText', {
      attrs: {
        "size-mobile": "10px",
        "size-desktop": "14px",
        "margin-top": "6px"
      }
    }, [_vm._v(" " + _vm._s(program.overview) + " ")])], 1)], 1);
  }), 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }