<template>
  <c-box
    :background-color="['#F2F2F2', '#FFF']"
    width="100%"
  >
    <c-box
      position="relative"
      mx="auto"
      :mt="isAuthenticated ? '0' : '16px'"
      :mb="['0', '16px']"
      :background-color="['#FFF', '#FFF']"
      border="1px solid #f2f2f2"
      :box-shadow="['none', '2px 2px 10px rgba(0, 0, 0, 0.15)']"
      :border-radius="['0px', '16px']"
      :min-height="['calc(100vh - 62px)', '74vh']"
      width="100%"
      max-width="1270px"
    >
      <Portal
        v-if="isAuthenticated"
        to="breadcrumb"
      >
        <c-box
          width="100%"
          max-width="1270px"
          mx="auto"
        >
          <BreadcrumbPath
            px="90px"
            py="20px"
            :paths="[
              { label: 'Dashboard', href: '/' },
              { label: 'Program', isCurrent: true },
            ]"
          />
        </c-box>
      </Portal>

      <c-box
        :padding="['16px 16px 0 16px']"
      >
        <c-box
          background-color="#FAF59A"
          width="100%"
          :height="['114px', '114px']"
          border-radius="12px"
          display="flex"
          justify-content="center"
          align-items="center"
          position="relative"
          overflow="hidden"
        >
          <c-box>
            <c-box
              position="absolute"
              top="0"
              left="0"
            >
              <svg
                width="79"
                height="64"
                viewBox="0 0 79 64"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0 -9H59L50.5 26.5L79 64H0V-9Z"
                  fill="#F1E456"
                />
              </svg>
            </c-box>

            <c-box
              position="absolute"
              bottom="0"
              left="0"
            >
              <svg
                width="79"
                height="37"
                viewBox="0 0 79 37"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0 0L55.5 22L79 73H0V0Z"
                  fill="#F1E456"
                />
              </svg>
            </c-box>

            <c-box
              position="absolute"
              top="0"
              left="50%"
              transform="translateX(-50%)"
            >
              <svg
                width="93"
                height="64"
                viewBox="0 0 93 64"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5 -9H84L93 26L84 64H5L0 26L5 -9Z"
                  fill="#F1E456"
                />
              </svg>
            </c-box>

            <c-box
              position="absolute"
              bottom="0"
              left="50%"
              transform="translateX(-50%)"
            >
              <svg
                width="79"
                height="41"
                viewBox="0 0 79 41"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0 11L79 0.5V84H0V11Z"
                  fill="#F1E456"
                />
              </svg>
            </c-box>

            <c-box
              position="absolute"
              top="50%"
              right="0"
              transform="translateY(-50%)"
            >
              <svg
                width="56"
                height="77"
                viewBox="0 0 56 77"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12.5 0.5L49.5 12.5L95 4V77H16L0.5 42L12.5 0.5Z"
                  fill="#F1E456"
                />
              </svg>
            </c-box>
          </c-box>

          <c-box
            position="absolute"
            height="100%"
            width="100%"
            display="flex"
            justify-content="center"
            align-items="center"
            gap="16px"
          >
            <BaseText
              size-mobile="16px"
              size-desktop="18px"
              color="#008C81"
            >
              Pilih Program
              <br>  
              Terbaik Untukmu
            </BaseText>
            <c-image
              height="93.66px"
              width="120px"
              :src="require('@/assets/images/illustration-10-raw.png')"
            />
          </c-box>
        </c-box>
      </c-box>

      <c-box
        :padding="['0 16px 16px 16px']"
        margin-top="16px"
        display="grid"
        grid-template-columns="repeat(2, 1fr)"
        gap="16px"
      >
        <c-box
          v-for="program in listProgram"
          :key="program.id"
          v-chakra="{
            _hover: {
              boxShadow: '2px 2px 10px rgba(0, 0, 0, 0.25)',
              backgroundColor: '#f1f1f1',
            },
            _active: {
              boxShadow: '2px 2px 10px rgba(0, 0, 0, 0.25)',
              backgroundColor: '#eaeaea',
            },
            'img': {
              transition: 'all 0.2s ease-in-out',
            },
            'img:hover': {
              transform: 'scale(1.1)',
            },
          }"
          as="button"
          text-align="start"
          :box-shadow="['2px 2px 10px rgba(0, 0, 0, 0.15)']"
          border-radius="8px"
          width="100%"
          display="flex"
          flex-direction="column"
          justify-content="center"
          align-items="center"
          overflow="hidden"
          @click="handleClickProgram(program)"
        >
          <c-box
            min-height="102px"
            height="100%"
            :max-height="['132px','252px']"
            overflow="hidden"
            width="100%"
          >
            <c-image
              :src="program.photoUrl"
              object-fit="cover"
              width="100%"
              height="100%"
              object-position="50% 20%"
            />
          </c-box>
          <c-box
            width="100%"
            padding="10px"
            margin-bottom="auto"
          >
            <BaseText
              size-mobile="14px"
              size-desktop="16px-2"
            >
              {{ program.name }}
            </BaseText>
            <BaseText
              size-mobile="10px"
              size-desktop="14px"
              margin-top="6px"
            >
              {{ program.overview }}
            </BaseText>
          </c-box>
        </c-box>
      </c-box>
    </c-box>
  </c-box>
</template>

<script>
import BreadcrumbPath from '@/components/elements/breadcrumb-path.vue'
import { mapGetters, mapActions } from 'vuex'
import BaseText from '@/components/elements/base-text.vue'
import { LIST_PROGRAMS } from '@/views/select-program/_constant/list-program'

export default {
  name: 'SelectProgram',
  components: { BaseText, BreadcrumbPath },
  data() {
    return {
      LIST_PROGRAMS,
      listProgram: [],
    }
  },
  computed: {
    ...mapGetters({
      isAuthenticated: 'auth/isAuthenticated',
    }),
  },
  mounted() {
    this.init()
  },
  methods: {
    ...mapActions({
      getProgramPageAllProduct: 'general/getProgramPageAllProduct',
    }),
    handleClickProgram(program) {
      if (program?.name?.toLowerCase() === 'for enterprise') {
        this.$router.push({
          name: 'client.enterpriseService',
        })
        return
      }
      this.$router.push({
        name: 'client.program.detail',
        params: {
          id: program?.slug ? program?.slug : program?.id,
        },
      })
    },
    async init() {
      const res = await this.getProgramPageAllProduct()
      this.listProgram = res.data
    },
  },
}
</script>
